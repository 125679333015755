import './App.css';
import { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import {Routes , Route } from "react-router-dom";
import ReactGA from 'react-ga4';

import Home from './pages/Home';
import WhoWrAre from './pages/WhoWrAre';
import CloudApplication from './pages/CloudApplication';
import DataAI from './pages/DataAI';
import DigitalInnovation from './pages/DigitalInnovation';
import IntelligentAutomation from './pages/IntelligentAutomation';
import ContactUs from './pages/ContactUs';
import ScrollToTop from './components/ScrollToTop';
import Ventures from './pages/Ventures';
import JoinUs from './pages/JoinUs';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Insights from './pages/Insights';
import Insight from './pages/Insight';

ReactGA.initialize('G-FP0B0D67N4');


function App() {


  useEffect(() => {
    // ReactGA.pageview(window.location.pathname);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search , title: "Home" });
  }, []);

  return (  
    <div className="App">
    <ScrollToTop />
      <Routes>
          <Route path='/' element={ <Home /> } exact />
          <Route path='/who-we-are' element={ <WhoWrAre /> } exact />
          <Route path='/who-we-are/#aboutus' element={ <WhoWrAre /> } exact />
          <Route path='/who-we-are/#ourvalues' element={ <WhoWrAre /> } exact />
          <Route path='/who-we-are/#ourteam' element={ <WhoWrAre /> } exact />
          <Route path='/who-we-are/#atechpartners' element={ <WhoWrAre /> } exact />
          <Route path='/ventures' element={ <Ventures /> } exact />
          <Route path='/what-we-do' element={ <CloudApplication /> } exact />
          <Route path='/cloud-and-application' element={ <CloudApplication /> } exact />
          <Route path='/data-and-ai' element={ <DataAI /> } exact />
          <Route path='/digital-innovation' element={ <DigitalInnovation /> } exact />
          <Route path='/intelligent-automation' element={ <IntelligentAutomation /> } exact />
          <Route path='/join-us' element={ <JoinUs /> } exact />
          <Route path='/what-we-think' element={ <Insights /> } exact />
          <Route path='/single/:slug/' element={ <Insight /> } exact />
          <Route path='/contact-us' element={ <ContactUs /> } exact />
          <Route path='/terms-and-condition' element={ <Terms /> } exact />
          <Route path='/privacy-policy' element={ <PrivacyPolicy /> } exact />
        </Routes>
    </div>
  );
}

export default App;
