import React from 'react'


import Menu from '../components/Menu'
import Header2 from '../components/Header2';
// import Datadriven from '../components/Datadriven';
import WwerContact from '../components/WwerContact';
import Footer from '../components/Footer';
// import HomeReadiness from '../components/HomeReadiness';
import AboutSection from '../components/AboutSection';
import TomorrowsChallenges from '../components/TomorrowsChallenges';
import { Helmet } from 'react-helmet';

import aboutimg from '../images/about-di.webp'

import bgvideo from "../video/DigitalInnovation_HD.mp4";
import bgvideomb from "../video/mobile/DigitalInnovation_HD_mb.mp4";


const DigitalInnovation = () => {

    // Header content 

    const heading = `Championing innovation with <br><span>best-in-class</span> capabilities`;
    // const btntext = "Let's innovate";
    const btntext = "Talk to us";
    const btnlink = "#contact";
    
    // about section content
    const abouttext = `<p>True innovation demands more than just an idea. We empower you to take the lead by <span>accelerating prototype development and testing</span> with our SpeedX experimentation platform. Rather than starting from scratch, we bring <span>cutting-edge solutions from disruptive startups</span>, enabling you to swiftly test hypothesis and introduce innovative solutions to the market. We don't just enable innovation; we supercharge it, helping you outpace your competitors.</p>`;

  return (
    <>
     <Helmet>
      <title>Digital Innovation</title>
      <meta property="og:title" content="Digital Innovation" />
      <meta name='og:description' content=''/>
      <meta name='keywords' content=''/>
    </Helmet>
    <div>
      <Menu />
      <Header2 class="di-bg" bgvideomb={bgvideomb} bgvideo={bgvideo} heading={heading} btntext={btntext} btnlink={btnlink} />
      <AboutSection img={aboutimg}  text={abouttext} />
      <TomorrowsChallenges /> 
      {/* <HomeReadiness /> */}
      <WwerContact />
      <Footer />      
    </div>
    </>

  )
}

export default DigitalInnovation
