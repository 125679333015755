import React, {useState} from 'react'
import { Formik, Form, useFormik, Field } from "formik";
import * as Yup from "yup";
import './position.css'
import { Row } from 'react-bootstrap';


const Position = (props) => {

    const [tab, setTab] = useState(false);
    const [hide, setHide] = useState(false);
    const [response, setResponse] = useState();
  
    const initialValues = {
      name: "",
      email: "",
      phone: "",
      message: "",
      resume:""
  }
  

  const subscribeSchema = Yup.object({
    name: Yup.string().min(2).max(50).required("Please enter your name").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    // company: Yup.string().min(2).max(50).required("Please enter your company name").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    email: Yup.string().email().min(2).required("Please enter valid email address").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email must be a valid email "),
    resume: Yup.mixed().required("Please select resume").test('fileSize', "File should be less than 6 MB", value => value.size <= 6291456).test("is-valid-type", "Please Select Only doc, docx and pdf file", value => isValidFileType(value && value.name.toLowerCase(), "image")),
    message: Yup.string().min(4).required("Please enter Message"),
    phone: Yup.string().required("Please enter your phone number")
.matches(/^([ +0-9]{1,4}|[ -0-9 ]{1,3})?[ 0-9 ]{10,14}$/, "Phone number is not valid ").min(10).max(16)
.matches(/^[0-9'+/-\s]+$/, "Only Numbers are allowed for this field "),
   });

//    const validFileExtensions = { image: ['doc', 'docx', 'pdf', 'png', 'jpg', 'jpeg'] };
   const validFileExtensions = { image: ['doc', 'docx', 'pdf'] };
   function isValidFileType(fileName, fileType) {
    return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
   }
   
  const { values, errors, touched, handleBlur, handleChange, handleSubmit , setFieldValue } =
  useFormik({
      initialValues,
      validationSchema: subscribeSchema,
      onSubmit: (values, action) => {


              const formData = new FormData();
              formData.append("jobid", props.ID);
              formData.append("jobrole", props.post_title);
              formData.append("name", values.name);
              formData.append("email", values.email);
              formData.append("phone", values.phone);
              formData.append("message", values.message);
              formData.append("resume", values.resume);
              setHide(true);

            //   https://sandboxmachine.com/blog-conceptvines

          fetch('https://blogs.conceptvines.com/wp-json/positions/mail', {
                  method: 'POST',
                  // mode: 'no-cors',
                  // headers: {
                  // 'Content-Type': 'application/json',
                  // },
                  
                  body:formData,
              })
          .then(res => res.json())
          .then(data => {
          // enter you logic when the fetch is successful
            setHide(false);
            // console.log(data)
            setResponse(data.message);
            // setModalShow(true);
            const fileInput = document.getElementById('file-input');
                if (fileInput) {
                    fileInput.value = '';
                }
            action.resetForm();
            setTimeout(()=>{
              setResponse("");
            }, 10000);
  
          })
      
          .catch(error => {
              // enter your logic for when there is an error (ex. error toast)
              console.log(error.message);
          })

        //   console.log(
        //     values
        //   );
   
          action.resetForm();
         
        
      },
  });
// console.log(

//     errors
// );


  return (
    <>
        <div className='position'>
            <h3>{props.post_title}</h3>
                <ul className='tag'>
                   <li><span>JD ID:</span> {props.ID}</li>
                   <li><span>Experience Level:</span> {props.experience}</li>        
                   <li><span>Location:</span> {props.location}</li>        
                </ul>
                                
            <p>{props.short_description}</p>
            <div className={(tab === true) ? "more-content show" : "more-content "} >
                <div dangerouslySetInnerHTML={{__html:props.post_content}} ></div>
                <div>
            <Formik>
                                    {() => (
                                        <Form onSubmit={handleSubmit}  encType="multipart/form-data">
                                          <Row className='mb-4'>
                                          <div className='col-md-6'>
                                                <label htmlFor="name" className="">Name</label>
                                                <Field type="text" name="name" placeholder='' aria-labelledby="name" value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {/* <ErrorMessage name="name" className="" component="div" /> */}
                                                <div className='errormsg'> {errors.name && touched.name ? (
                                                    <p className="form-error">{errors.name}</p>
                                                ) : null}</div>
                                            </div>

                                            <div className='col-md-6'>
                                                <label htmlFor="email" className="">Email</label>
                                                <Field type="text" name="email" placeholder='' aria-labelledby="email" value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {/* <ErrorMessage name="email" component="div" /> */}
                                                <div className='errormsg'> {errors.email && touched.email ? (
                                                <p className="form-error">{errors.email}</p>
                                            ) : null}</div>
                                            </div>

                                          </Row>
                                          <Row className='mb-4'>
                                          <div className='col-md-6'>
                                                <label htmlFor="name" className="">Phone Number</label>
                                                <Field type="text" name="phone" placeholder='' aria-labelledby="phone" value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {/* <ErrorMessage name="name" className="" component="div" /> */}
                                                <div className='errormsg'> {errors.phone && touched.phone ? (
                                                    <p className="form-error">{errors.phone}</p>
                                                ) : null}</div>
                                            </div>

                                            <div className='col-md-6'>
                                                <label htmlFor="resume" className="">Upload Resume</label>
                                                <Field id='file-input' type="file" name="resume" placeholder='' aria-labelledby="resume" 
                                                    // onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    accept=".doc, .docx,.pdf" 
                                                    // accept=".doc, .docx,.pdf, image/*" 
                                                    onChange={(event) => {
                                                      const files = event.target.files[0];
                                                      setFieldValue("resume", files);
                                                    }}
                                                    />

                                                    
                                                {/* <ErrorMessage name="resume" component="div" /> */}
                                                <div className='errormsg'> {errors.resume && touched.resume ? (
                                                <p className="form-error">{errors.resume}</p>
                                            ) : null}</div>
                                            </div>
                                        
                                          </Row>
                                        
                                          <Row className='mb-5'>
                                          <div className='col-md-12'>
                                                <label htmlFor="email" className="">Message</label>
                                                <Field component="textarea" name="message" row="15"  placeholder='' aria-labelledby="message" value={values.message}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {/* <ErrorMessage name="email" component="div" /> */}
                                                <div className='errormsg'> {errors.message && touched.message ? (
                                                <p className="form-error">{errors.message}</p>
                                            ) : null}</div>
                                            </div>
                                         
                                          </Row> 

                                            <div className='text-center'>
                                                {(hide) ? (
                                                        <button 
                                                        disabled
                                                        style={{opacity:"0.5"}}
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </button>
                                                    
                                                    ) :  (
                                                        <button type="submit">Submit</button>                       
                                                        )
                                                    }
                                            </div>

                                            <div className="form-success text-center mt-5"    dangerouslySetInnerHTML={{ __html: response }}></div>  

                                     
                                        </Form>
                                    )}
                                </Formik>
                         
            </div>

            </div>
    
    
            {(tab !== true) ? <div className='view-more' onClick={()=>{setTab(true)}}>View Detail Requirement</div> : <div className='view-more' onClick={()=>{setTab(false)}}>View Less</div>}                            
       
       </div>
                  
    </>
  )
}

export default Position
