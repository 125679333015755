import React from 'react'
import { Row } from 'react-bootstrap';
import './atechpartner.css';

import pimg1 from '../images/AWS-Logo-w.webp';
import pimg2 from '../images/GCloud-Logo-w.webp';
import pimg3 from '../images/Snowflake_Logo-w.webp';
import pimg4 from '../images/databricks_logo-w.webp';
import pimg5 from '../images/Pega-Logo-w.webp';
// import pimg6 from '../images/Appian-w.webp';
import pimg6 from '../images/Meta-logo.webp';

import pimg7 from '../images/Azure-Logo-w.webp';
import pimg8 from '../images/Terraform_Logo-w.webp';
import pimg9 from '../images/OpenAI-Logo-w.webp';
import pimg10 from '../images/Anthropic_logo-w.webp';
import pimg11 from '../images/UiPath-w.webp';
import pimg12 from '../images/hf-logo.webp';


import pimgmb1 from '../images/AWS-Logo-w-mb.webp';
import pimgmb2 from '../images/GCloud-Logo-w-mb.webp';
import pimgmb3 from '../images/Snowflake_Logo-w-mb.webp';
import pimgmb4 from '../images/databricks_logo-w-mb.webp';
import pimgmb5 from '../images/Pega-Logo-w-mb.webp';
import pimgmb6 from '../images/Meta-logo.webp';

import pimgmb7 from '../images/Azure-Logo-w-mb.webp';
import pimgmb8 from '../images/Terraform_Logo-w-mb.webp';
import pimgmb9 from '../images/OpenAI-Logo-w-mb.webp';
import pimgmb10 from '../images/Anthropic_logo-w-mb.webp';
import pimgmb11 from '../images/UiPath-w-mb.webp';
import pimgmb12 from '../images/hf-logo.webp';



const AtechPartners = () => {
  return (
    <section className='atechpartner-section' id='atechpartners'>
        <div className="container py-5">
         
            <Row className='py-5 text-center'>
                <div className='col-md-12 pt-5 pb-3'>
                    <h1>Advanced technology partners</h1>
                </div>

                <div className='py-5 partners-6 desktop'>
                    <img src={pimg1} alt='' />
                    <img src={pimg2} alt='' />
                    <img src={pimg3} alt='' />
                    <img src={pimg4} alt='' />
                    <img src={pimg5} alt='' />
                    <img src={pimg6} alt='' />
                </div>

                <div className='py-5 partners-5 desktop'>
                    <img src={pimg7} alt='' />
                    <img src={pimg8} alt='' />
                    <img src={pimg9} alt='' />
                    <img src={pimg10} alt='' />
                    <img src={pimg11} alt='' />
                    <img src={pimg12} alt='' />
                </div>


                <div className='partners-6 mobile'>
                    <img src={pimgmb1} alt='' />
                    <img src={pimgmb2} alt='' />
                    <img src={pimgmb3} alt='' />
                    <img src={pimgmb4} alt='' />
                    <img src={pimgmb5} alt='' />
                    <img src={pimgmb6} alt='' />
                    <img src={pimgmb7} alt='' />
                    <img src={pimgmb9} alt='' />
                    <img src={pimgmb11} alt='' />
                </div>

                <div className=' partners-5 mobile '>
                    <img src={pimgmb8} alt='' />
                    <img src={pimgmb10} alt='' />
                    <img src={pimgmb12} alt='' />
                </div>

                <div className='partners-6 tablet'>
                    <img src={pimg1} alt='' />
                    <img src={pimg2} alt='' />
                    <img src={pimg3} alt='' />
                    <img src={pimg4} alt='' />
                    <img src={pimg5} alt='' />
                    <img src={pimg6} alt='' />
                    <img src={pimg7} alt='' />
                    <img src={pimg9} alt='' />
                    <img src={pimg11} alt='' />
                    <img src={pimg8} alt='' />
                    <img src={pimg10} alt='' />
                    <img src={pimg12} alt='' />
                </div>

            </Row>

        </div>
        
    </section>
  )
}

export default AtechPartners
