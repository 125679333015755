import React from 'react'
import { Row } from 'react-bootstrap';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import './homedisruptors.css';


    import img1 from '../images/modjoul-logo.webp';
    import img2 from '../images/AntWorks-Logo.webp';
    import img3 from '../images/KYG-Trade.webp';
    import img4 from '../images/Vee24.webp';
    import img5 from '../images/accumulate.webp';
    import img6 from '../images/Redaptive_Logo.webp';
    import img7 from '../images/c2fo.webp';
    import img8 from '../images/Inveniam.webp';
    import img9 from '../images/neem.webp';
    import img10 from '../images/oasis-pro.webp';
    import img11 from '../images/get-stabl.webp';
    import img12 from '../images/Nimbly.webp';
    import img13 from '../images/yodda.webp';
    import img14 from '../images/giggso.webp';
    import img15 from '../images/rialtomarkets-logo.webp';
    import img16 from '../images/Sriya_ai.webp';
    import img17 from '../images/cogecognextlogo.svg';
    

    import img1mb from '../images/modjoul-logo-mb.webp';
    import img2mb from '../images/AntWorks-Logo-mb.webp';
    import img3mb from '../images/KYG-Trade-mb.webp';
    import img4mb from '../images/Vee24-mb.webp';
    import img5mb from '../images/yodda-mb.webp';
    import img6mb from '../images/Redaptive_Logo-mb.webp';
    import img7mb from '../images/c2fo-mb.webp';
    import img8mb from '../images/Nimbly-mb.webp';
    import img9mb from '../images/Inveniam-mb.webp';
    import img10mb from '../images/neem-mb.webp';
    import img11mb from '../images/oasis-pro-mb.webp';
    import img12mb from '../images/get-stabl-mb.webp';
    import img13mb from '../images/giggso-mb.webp';
    import img14mb from '../images/rialtomarkets-logo-mb.webp';
    import img15mb from '../images/accumulate-mb.webp';
    import img16mb from '../images/Sriya_ai-mb.webp';
    import img17mb from '../images/cogecognextlogo-mb.svg';
    
    
    
  const brands = [
    {
      img:img1,
      link:"https://modjoul.com/"
    },
    {
      img:img2,
      link:"https://www.ant.works/"
    },
    {
      img:img3,
      link:"https://www.kygtrade.com/"
    },
    {
      img:img4,
      link:"https://vee24.com/"
    },
    {
      img:img5,
      link:"https://accumulatenetwork.io/"
    },
    {
      img:img9,
      link:"https://www.neem.io/"
    },
    {
      img:img10,
      link:"https://oasispro.com/"
    },
    {
      img:img11,
      link:"https://www.getstabl.com/"
    },
    {
      img:img12,
      link:"https://www.nimblymade.com/"
    },
    {
      img:img14,
      link:"https://www.giggso.com/"
    },    
    {
      img:img7,
      link:"https://c2fo.com/"
    },

    {
      img:img15,
      link:"https://rialtomarkets.com/"
    },
    {
      img:img8,
      link:"https://www.inveniam.io/"
    },
    {
      img:img13,
      link:"https://www.yodda.care/"
    },
    {
      img:img6,
      link:"https://redaptive.com/"
    },    {
      img:img16,
      link:"https://sriya.ai/"
    },
    {
      img:img17,
      link:"https://cognext.ai/global/"
    },
    
  ]

  const brandimgsmb = [
    {
      img:img1mb,
      link:"https://modjoul.com/"
    },
    {
      img:img2mb,
      link:"https://www.ant.works/"
    },
    {
      img:img3mb,
      link:"https://www.kygtrade.com/"
    },
    {
      img:img4mb,
      link:"https://vee24.com/"
    },
    {
      img:img5mb,
      link:"https://www.yodda.care/"
    },
    {
      img:img6mb,
      link:"https://redaptive.com/"
    },
    {
      img:img7mb,
      link:"https://c2fo.com/"
    },
    {
      img:img8mb,
      link:"https://www.nimblymade.com/"
    },
    {
      img:img9mb,
      link:"https://www.inveniam.io/"
    },
    {
      img:img10mb,
      link:"https://www.neem.io/"
    },
    {
      img:img11mb,
      link:"https://oasispro.com/ "
    },
    {
      img:img12mb,
      link:"https://www.getstabl.com/"
    },
    {
      img:img13mb,
      link:"https://www.giggso.com/"
    },
    {
      img:img14mb,
      link:"https://rialtomarkets.com/"
    },
    {
      img:img15mb,
      link:"https://accumulatenetwork.io/"
    },
    {
      img:img16mb,
      link:"https://sriya.ai/"
    },
    {
      img:img17mb,
      link:"https://cognext.ai/global/"
    },
  ]


const HomeDisruptors = () => {
  return (
    <section className='disruptors-section'>
        <div className='container-fluid'>
            <Row className="text-center mb-5 my-md-5">
                <div className='col-md-8 mx-auto'>
                    <h1>Our Curated Network of <br /> <span>Game-Changing</span> Disruptors</h1>
                </div>
            </Row>

            <div className='mx-auto w-100 desktop'>
              <Swiper
                            slidesPerView={4}
                            spaceBetween={50}
                            centeredSlides={false}
                            loop={true}
                            autoplay={{
                              delay: 3000,
                              disableOnInteraction: false,
                            }}
                            pagination={false}
                            // pagination={{ clickable: true }}
                            navigation={false}
                          //modules={[ Pagination, Navigation]}
                            modules={[Autoplay]}
                            className="brand-slider"
                          >

                            {
                            brands.map((d, index )=>{
                              return (
                                <SwiperSlide key={index}>
                                <a href={d.link} target='__blank'>
                                <img src={d.img} alt='' className={(index === 10 ) ? "small" : (index === 15 || index === 16) ? "small60" : ""} />
                                </a>          
                              </SwiperSlide>
                              )
                            })
                            }

              </Swiper>
            </div>

            <div className='mobile tablet'>
                 <div className='brands-logo'>
                 {
                    brandimgsmb.map((d, index )=>{
                       return (
                        <div key={index}>
                        <a href={d.link} target='__blank' key={index}>
                          <img src={d.img} alt=''  className={(index >= 10) ? "small"+index : "" } />
                         </a>
                         </div>          
                        )
                      })
                  }
 
                 </div>             
            </div>

              
        </div>
    </section>
  )
}

export default HomeDisruptors
