import React from 'react'
import { NavLink } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import './homewhowr.css';

import img from '../images/3d-rendering-abstract-black-white.webp'
import imgmb from '../images/3d-rendering-abstract-black-white-mb.webp'

import img1 from '../images/cloud-native-adoption.webp'
import img2 from '../images/data&analytics.webp'
import img3 from '../images/digitalinnovation.webp'
import img4 from '../images/intelligent-automation.webp'

const HomeWhoWR = () => {
  return (
    <>
    <section className='homewr-section'>
        <div className='container'>
            <div className='row align-items-center  g-5'>
                <div className='col-md-8 order-m-1'>
                    <div className='bl-green'>
                    <div className='text-box'>
                        <div className='p'>
                        We are an <span>AI-first</span>, digital engineering firm that helps accelerate modernization and digital innovation. 
                        We bring the <span>best-of-breed</span> capabilities to <span>transform businesses with unrivaled</span> <div className="ms-slider">
                            <ul className="ms-slider__words">
                                <li className="ms-slider__word">speed.</li>
                                <li className="ms-slider__word">safety.</li>
                                <li className="ms-slider__word">certainty.</li>
                                <li className="ms-slider__word">speed</li>
                            </ul>
                         </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <img className='desktop' src={img} alt='' />
                    <img className='mobile tablet mx-auto' src={imgmb} alt='' />
                </div>
            </div>
        </div>
    </section>
    <section className='Aifirst-section'>
        <div className='container py-5'>
            <div className='bl-green'>
                <div className='text-box'>
                    <h2><span>AI-First,</span> <br />
                    Outcome driven arsenal of solutions</h2>
                </div>
            </div>
        </div>

        <div className='container'>
            <Row className='align-items-center'>   
                <div className='col-md-6 order-m-1'>
                    <h3>Cloud & Applications</h3>
                    <h4>Cloud-native adoption made seamless. </h4>
                    <p>We solve for automation by implementing and managing infrastructure as code, along with conquering complexity in containerization, scalability, and flexibility between micro and monolithic systems.</p>
                    <NavLink className='slide-btn' to='/cloud-and-application' >Transform today</NavLink>
                </div>
                <div className='col-md-6 text-right'>
                    <img src={img1} alt='' />
                </div>
            </Row>
            <Row>
                <div className='col-md-6'>
                    <img src={img2} alt='' />
                </div>   
                <div className='col-md-6'>
                    <h3>Data & Artificial Intelligence </h3>
                    <h4>Data holds the key.  </h4>
                    <p>We use data to unlock effective, strategic decision-making. We’ll help you take command of your data with the utmost integrity, ensuring compliance and security.</p>
                    <NavLink className='slide-btn' to='/data-and-ai' >Unleash data</NavLink>
                </div>
                
            </Row>
            <Row>   
                <div className='col-md-6 order-m-1'>
                    <h3>Digital Innovation </h3>
                    <h4>Shaping the next-gen digital.</h4>
                    <p>Our agile approach completely reimagines application, product and platform development, enabling you to increase customer engagement, accelerate time-to-market, and deploy innovations with newfound speed and cost efficiency.</p>
                    <NavLink className='slide-btn' to='/digital-innovation' >Let's innovate</NavLink>
                </div>
                <div className='col-md-6 text-right'>
                    <img src={img3} alt='' />
                </div>
            </Row>
            <Row>   
                <div className='col-md-6'>
                    <img src={img4} alt='' />
                </div>
                <div className='col-md-6'>
                    <h3>Intelligent Automation </h3>
                    <h4>Smart automation. Smarter businesses.</h4>
                    <p>We simplify and accelerate the integration of complex AI-driven automation tools in your processes to improve speed, efficiency and, productivity.</p>
                    <NavLink className='slide-btn' to='/intelligent-automation' >Unlock resilience</NavLink>
                </div>
            </Row>
        </div>
    </section>
    </>
  )
}

export default HomeWhoWR
