import React from 'react'

import Menu from '../components/Menu'
import Header2 from '../components/Header2';
import BeyondTheDesk from '../components/BeyondTheDesk';
import NextChapter from '../components/NextChapter';
import Footer from '../components/Footer';

import { Helmet } from 'react-helmet';

import bgvideo from "../video/JoinUs.mp4";
import bgvideomb from "../video/mobile/JoinUs_mb.mp4";


const JoinUs = () => {
    // Header content 
    const heading = `Your Moment is Now: Pioneer the <br />
    Breakthroughs of <span>Tomorrow</span>  `;
    // const btntext = "Elevate your future";
    // const btnlink = "/contact-us";
    
  return (
    <>
    <Helmet>
      <title>Join Us</title>
      <meta property="og:title" content="Join Us" />
      <meta name='og:description' content=''/>
      <meta name='keywords' content=''/>
    </Helmet>
    <div>
        <Menu />
        <Header2 class="header-joinus" bgvideomb={bgvideomb} bgvideo={bgvideo} heading={heading} />
        <BeyondTheDesk /> 
        <NextChapter />
        <Footer />      
    </div>
    </>

  )
}

export default JoinUs
