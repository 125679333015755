import React from 'react'
import { HashLink } from 'react-router-hash-link'
import { Row } from 'react-bootstrap'
import './competitiveedge.css'

const CompetitiveEdge = () => {
  return (
    <>
    <section className="competitiveedge-section">
        <div className="container">
            <div className="main-box">
                <h1>Capabilities to build a <span>competitive</span> edge</h1>
            </div>
        </div>

        <div className="container-fluid px-0 mx-0 desktop">
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                        <div className="bg-1"></div>
                    {/* <img src={img1} alt="" /> */}
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5 ps--5">
                        <h3>Process Intelligence/Mining</h3>
                        <h4>Transform Your Processes with Intelligence</h4>
                        
                        <p>By leveraging advanced analytics, AI, and machine learning, we provide deep insights into your processes, identifying inefficiencies, and uncovering opportunities for improvement and automation.</p>

                        <p>What sets us apart:</p>
                        <ul>
                            <li>Custom solutions aligned with organizational structures and hierarchies</li>
                            <li>Iterative and adaptive approach</li>
                            <li>Application of AI and advanced analytics</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                           <p className="inline-list">AI and Machine Learning  |  Advanced Process Analytics  |  Enhanced Data Visualization and Interactive Dashboards  |  Process Mining as a Service (PMaaS)  |  Continuous Monitoring and Real-Time Process Mining  |  Convergence with Robotic Process Automation (RPA)</p>                  
                    </div>
                </div>
            </Row>
            <Row className="g-5">   
            <div className="col-md-8">
                <div className="text-side px-5 ms-5">
                        <h3>BPM & RPA</h3>
                        <h4>Maximize efficiency by streamlining operations</h4>
                        <p>From assessing the key areas best suited for RPA to creating a customized strategy for implementation, we integrate software to automate highly routine tasks to drive higher efficiency and productivity.</p>
                        <p>What sets us apart:</p>
                        
                        <ul>
                          <li>RPA strategy customized according to your business context and nuance</li>
                          <li>Scalable and flexible solutions</li>
                          <li>Ongoing maintenance and optimization</li>
                          <li>User-Friendly RPA Development</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">Hyperautomation  |  Integration of AI and Machine Learning  |  Cloud-Based RPA Solutions  |  Enhanced Cognitive Capabilities  |  RPA as a Service (RPAaaS)  |  Citizen development  |  Combination with IoT</p>                  
                    </div>
                </div>
                <div className="col-md-4 text-center">
                    <div className="bg-2"></div>
                      {/* <img src={img2} alt="" /> */}
                </div>
            </Row>
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                      <div className="bg-3"></div>
                      {/* <img src={img3} alt="" /> */}
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5 ps--5">
                        <h3>Low Code Automation</h3>
                        <h4>Transform business processes</h4>

                        <p>We harness the power of Low-Code Application Platforms (LCAPs) such as Azure Power Apps, and Pega Infinity to spearhead business transformation at breakneck speed.</p>
                        <p>What sets us apart:</p>
                        <ul>
                            <li>Rapid Application Development with LCAPs</li>
                            <li>Democratization of the development process by empowering citizen developers</li>
                            <li>Integration of AI, automation, and data analytics to enhance the functionality of applications</li>
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">AI-Enhanced Low-Code Development  |  Multi-Experience Development  |  Scalability and Cloud Integration  |  Focus on Security and Compliance</p>
                    </div>
                </div>
            </Row>
            <Row className="g-5">   
            <div className="col-md-8">
                    <div className="text-side px-5 ms-5">
                        <h3>AI/ML Augmentation</h3>
                        <h4>Unlock unmatched efficiency</h4>

                        <p>Our solutions leverage the latest in artificial intelligence and machine learning to unlock new levels of efficiency in business operations, products and services.</p>
                        <p>What sets us apart:</p>

                        <ul>
                            <li>Continuously learning industry and function-specific AI ML models</li>
                            <li>Ethical AI and bias mitigation</li>
                            <li>GenAI plug and play modules</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                            <p className="inline-list">Automated Machine Learning  |  AI-powered process automation  |  Real-Time Predictive Analytics  |  Natural Language Processing (NLP) for Enhanced Interaction  |  Ethical AI and Bias Mitigation  |  AI in Cybersecurity</p>
                    </div>
                </div>
                <div className="col-md-4 text-center">
                      <div className="bg-4"></div>
                      {/* <img src={img4} alt="" /> */}
                </div>
            </Row>
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                      <div className="bg-5"></div>
                      {/* <img src={img3} alt="" /> */}
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5 ps--5">
                        <h3>Conversational Bots</h3>
                        <h4>Solve smarter with smart chats</h4>
                        <p>We custom-create solutions to meet specific business needs. Our bots are designed for seamless integration with existing digital platforms, offering intuitive and intelligent interactions that drive engagement, improve customer service, and boost operational efficiency.</p>
                        <p>What sets us apart:</p>
                        <ul>
                            <li>User-first, intuitive design approach</li>
                            <li>Foundation of continuously incorporating learning from interactions</li>
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">Advanced Natural Language Processing (NLP)  |  Voice-Activated Interfaces  |  Integration with IoT Devices  |  Emotion AI | Cross-Platform Connectivity</p>
                    </div>
                </div>
            </Row>
            <Row className="g-5">   
            <div className="col-md-8">
                    <div className="text-side px-5 ms-5">
                        <h3>Digital Twins (AR/VR)</h3>
                        <h4>Simulate to predict, perfect and prosper</h4>

                        <p>Our solutions empower businesses to visualize complex data, simulate scenarios without physical risks, and enhance the interaction with digital models in real time.</p>
                        <p>What sets us apart:</p>

                        <ul>
                            <li>User-centric, intuitive interfaces and realistic simulations</li>
                            <li>Continuous validation and iteration</li>
                            <li>Data integrity to safeguard sensitive information</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                            <p className="inline-list">Integration with IoT  |  AI-Powered Analytics  |  Sustainability and Efficiency  |  Scalability across Industries</p>
                    </div>
                </div>
                <div className="col-md-4 text-center">
                      <div className="bg-6"></div>
                      {/* <img src={img4} alt="" /> */}
                </div>
            </Row>
            <Row>
                <div className="col-md-12 text-center py-5">
                    <HashLink to="#contact" className="main-btn">Unlock resilience</HashLink>
                </div>
            </Row>
        </div>

        <div className="container-fluid px-0 mt-5 mobile tablet">
                <div className="bg-img bg-1">
                    <div className="text-side">
                        <h3>Process Intelligence/Mining</h3>
                        <h4>Transform Your Processes with Intelligence</h4>
                        
                        <p>By leveraging advanced analytics, AI, and machine learning, we provide deep insights into your processes, identifying inefficiencies, and uncovering opportunities for improvement and automation.</p>

                        <p>What sets us apart:</p>
                        <ul>
                            <li>Custom solutions aligned with organizational structures and hierarchies</li>
                            <li>Iterative and adaptive approach</li>
                            <li>Application of AI and advanced analytics</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                           <p className="inline-list">AI and Machine Learning  |  Advanced Process Analytics  |  Enhanced Data Visualization and Interactive Dashboards  |  Process Mining as a Service (PMaaS)  |  Continuous Monitoring and Real-Time Process Mining  |  Convergence with Robotic Process Automation (RPA)</p>                  
                    </div>
                </div>
                <div className="bg-img bg-2">
                    <div className="text-side">
                        <h3>BPM & RPA</h3>
                        <h4>Maximize efficiency by streamlining operations</h4>
                        <p>From assessing the key areas best suited for RPA to creating a customized strategy for implementation, we integrate software to automate highly routine tasks to drive higher efficiency and productivity.</p>
                        <p>What sets us apart:</p>
                        
                        <ul>
                          <li>RPA strategy customized according to your business context and nuance</li>
                          <li>Scalable and flexible solutions</li>
                          <li>Ongoing maintenance and optimization</li>
                          <li>User-Friendly RPA Development</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">Hyperautomation  |  Integration of AI and Machine Learning  |  Cloud-Based RPA Solutions  |  Enhanced Cognitive Capabilities  |  RPA as a Service (RPAaaS)  |  Citizen development  |  Combination with IoT</p>                  
                    </div>
                </div>

                <div className="bg-img bg-3"> 
                    <div className="text-side">
                        <h3>Low Code Automation</h3>
                        <h4>Transform business processes</h4>

                        <p>We harness the power of Low-Code Application Platforms (LCAPs) such as Azure Power Apps, Appian, and Pega Infinity to spearhead business transformation at breakneck speed.</p>
                        <p>What sets us apart:</p>
                        <ul>
                            <li>Rapid Application Development with LCAPs</li>
                            <li>Democratization of the development process by empowering citizen developers</li>
                            <li>Integration of AI, automation, and data analytics to enhance the functionality of applications</li>
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">AI-Enhanced Low-Code Development  |  Multi-Experience Development  |  Scalability and Cloud Integration  |  Focus on Security and Compliance</p>
                    </div>
                </div>

                <div className="bg-img bg-4">
                    <div className="text-side">
                        <h3>AI/ML Augmentation</h3>
                        <h4>Unlock unmatched efficiency</h4>

                        <p>Our solutions leverage the latest in artificial intelligence and machine learning to unlock new levels of efficiency in business operations, products and services.</p>
                        <p>What sets us apart:</p>

                        <ul>
                            <li>Continuously learning industry and function-specific AI ML models</li>
                            <li>Ethical AI and bias mitigation</li>
                            <li>GenAI plug and play modules</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                            <p className="inline-list">Automated Machine Learning  |  AI-powered process automation  |  Real-Time Predictive Analytics  |  Natural Language Processing (NLP) for Enhanced Interaction  |  Ethical AI and Bias Mitigation  |  AI in Cybersecurity</p>
                    </div>
                </div>
                <div className="bg-img bg-5">
                    <div className="text-side">
                        <h3>Conversational Bots</h3>
                        <h4>Solve smarter with smart chats</h4>
                        <p>We custom-create solutions to meet specific business needs. Our bots are designed for seamless integration with existing digital platforms, offering intuitive and intelligent interactions that drive engagement, improve customer service, and boost operational efficiency.</p>
                        <p>What sets us apart:</p>
                        <ul>
                            <li>User-first, intuitive design approach</li>
                            <li>Foundation of continuously incorporating learning from interactions</li>
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">Advanced Natural Language Processing (NLP)  |  Voice-Activated Interfaces  |  Integration with IoT Devices  |  Emotion AI | Cross-Platform Connectivity</p>
                    </div>
                </div>

                <div className="bg-img bg-6">
                    <div className="text-side">
                        <h3>Digital Twins (AR/VR)</h3>
                        <h4>Simulate to predict, perfect and prosper</h4>

                        <p>Our solutions empower businesses to visualize complex data, simulate scenarios without physical risks, and enhance the interaction with digital models in real time.</p>
                        <p>What sets us apart:</p>

                        <ul>
                            <li>User-centric, intuitive interfaces and realistic simulations</li>
                            <li>Continuous validation and iteration</li>
                            <li>Data integrity to safeguard sensitive information</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                            <p className="inline-list">Integration with IoT  |  AI-Powered Analytics  |  Sustainability and Efficiency  |  Scalability across Industries</p>
                    </div>
                </div>
            <Row>
                <div className="col-md-12 text-center py-0">
                    <HashLink to="#contact" className="main-btn">Unlock resilience</HashLink>
                </div>
            </Row>
        </div>

    </section>
    </>

  )
}

export default CompetitiveEdge