import React from 'react'
import Menu from '../components/Menu'
import Header2 from '../components/Header2'
import WwerContact from '../components/WwerContact'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'
import Addresses from '../components/Addresses'

import bgvideo from "../video/ContactUs_HD.mp4";
import bgvideomb from "../video/mobile/ContactUs_HD_mb.mp4";

const ContactUs = () => {

    // Header content 
    const heading = `Your competitive <br />edge <span>starts</span> here `;
    // const btntext = "Elevate your future";
    // const btnlink = "/";
    
    
  return (
    <>
    <Helmet>
      <title>Contact Us</title>
      <meta property="og:title" content="Contact Us" />
      <meta name='og:description' content=''/>
      <meta name='keywords' content=''/>
    </Helmet>
    <div>
        <Menu />
        <Header2 class="contactus-bg" bgvideomb={bgvideomb} bgvideo={bgvideo} heading={heading} />
        <Addresses />
        <WwerContact />
        <Footer />      
    </div>
    </>

  )
}

export default ContactUs
