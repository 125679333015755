import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../components/Menu'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import TermsBody from '../components/TermsBody'

const Terms = () => {
  return (
    <>
    <Helmet>
      <title>Terms And Conditions</title>
      <meta property="og:title" content="Terms And Conditions" />
      <meta name='og:description' content='Terms And Conditions'/>
      <meta name='keywords' content=''/>
    </Helmet>
   
    <div>
        <Menu />
        <Header2 class="terms"/>
        <TermsBody />
        <Footer />      
    </div>

    </>
  )
}

export default Terms
