import React, { useState } from 'react'
import brandlogo from "../images/Brand Logo.png";
import brandlogomb from "../images/brand-logo-mb.webp";
import Container from 'react-bootstrap/Container';
//import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Outlet, NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useParams } from 'react-router-dom';



import './menu.css';

const Menu = () => {
    let { id } = useParams();  
    let expand = 'md';
    let homeurl = "/";

    const [fix, setFix] = useState(false);

    const [show, setShow] = useState(false);
    const [showwwr, setShowwwr] = useState(false);


    const showDropdown = (e)=>{
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }


    const showDropdownWwr = (e)=>{
        setShowwwr(!showwwr);
    }
    const hideDropdownWwr = e => {
        setShowwwr(false);
    }


    const scrollToTop = () => {
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
        }, 600);
    }

    
    function setFixed() {
        if(window.screenY >= 100) {
            setFix(true);
        } else {
            setFix(false);
        }
    }

    window.addEventListener("scroll",setFixed);
    

    window.addEventListener('scroll', function() {
        let scrollPosition = window.scrollY;
        // Use the scrollPosition as needed
        // console.log(scrollPosition);
        if (scrollPosition > 100) {
            setFix(true);
        } else {
            setFix(false);
        }
    });
    

    const [showc, setShowc] = useState(false);

    const handleClosehash = () => setShowc(false);
    const handleClose = () => {
        scrollToTop();
        setShowc(false);
    }
    const handleShow = () => setShowc(true);

  return (
    <>
    <div className={(fix) ? "menu fixed" : "menu"}>
        <Container fluid className='hero px-5 pt-3'>   
            <Navbar key={expand} bg="transparent"  expand={expand} className="navbar-dark  ps-3 pe-5 mb-3">
                <Navbar.Brand href={homeurl}>
                    <img className='desktop tablet' src={id !== "" ? brandlogo : brandlogo } alt='Concept Vines' />
                    <img className='mobile' src={id !== "" ? brandlogomb : brandlogomb } alt='Concept Vines' />
                </Navbar.Brand>
                <Navbar.Toggle onClick={handleShow} aria-controls={`offcanvasNavbar-expand-${expand}`} ></Navbar.Toggle>
                <Navbar.Offcanvas
                show={false}
                onHide={handleClose} 
                onshow={handleShow}
                id={`offcanvasNavbar-expand-${expand}`}
                className={(showc) ? 'offcanvas offcanvas-end show' : 'offcanvas offcanvas-end'}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                >
                {/* <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={handleShow} ></Navbar.Toggle>
                <Navbar.Offcanvas show={showc} onHide={handleClose}
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                > */}
                    <Offcanvas.Header>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                            <NavLink to="/"><img src={id !== "" ? brandlogo : brandlogo }  alt="Logo" /></NavLink>
                        </Offcanvas.Title>
                        <button type="button" onClick={handleClose} className="btn-close" aria-label="Close"></button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
              
                        <Nav className="justify-content-end flex-grow-1 pe-3">
              
                            
                            <NavDropdown 
                                className={`services desktop tablet`}
                                title={<NavLink to="/who-we-are" onClick={scrollToTop} >Who we are</NavLink>}
                                id={`offcanvasNavbarDropdown-service-expand-${expand}`}
                                show={showwwr}
                                onMouseEnter={showDropdownWwr} 
                                onMouseLeave={hideDropdownWwr}  
                            >
                                <HashLink smooth  to="/who-we-are/#aboutus"  className="dropdown-item">About Us</HashLink>
                                <HashLink smooth  to="/who-we-are/#ourvalues"  className="dropdown-item">Our Values</HashLink>
                                <HashLink smooth  to="/who-we-are/#ourteam"  className="dropdown-item">Our Team</HashLink>
                                <HashLink smooth  to="/who-we-are/#atechpartners"  className="dropdown-item">Advanced Technology Partners</HashLink>
                               
                            </NavDropdown>

                            <NavDropdown 
                                className={`services  mobile`}
                                title={<NavLink to="/who-we-are" onClick={scrollToTop} >Who we are</NavLink>}
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                            >
                                <HashLink smooth onClick={handleClosehash}  to="/who-we-are/#aboutus"  className="dropdown-item">About Us</HashLink>
                                <HashLink smooth onClick={handleClosehash}  to="/who-we-are/#ourvalues"  className="dropdown-item">Our Values</HashLink>
                                <HashLink smooth onClick={handleClosehash} to="/who-we-are/#ourteam"  className="dropdown-item">Our Team</HashLink>
                                <HashLink smooth onClick={handleClosehash} to="/who-we-are/#atechpartners"  className="dropdown-item">Advanced Technology Partners</HashLink>
                               
                            </NavDropdown>

                            <NavDropdown 
                                className={`services desktop tablet`}
                                title={<NavLink to="/what-we-do" onClick={scrollToTop} >What we do</NavLink>}
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                                show={show}
                                onMouseEnter={showDropdown} 
                                onMouseLeave={hideDropdown}  
                            >
                                <NavLink to="/cloud-and-application" onClick={scrollToTop}  className="dropdown-item">Cloud & Applications</NavLink>
                                <NavLink to="/data-and-ai" onClick={scrollToTop}  className="dropdown-item">Data & Artificial Intelligence</NavLink>
                                <NavLink to="/digital-innovation" onClick={scrollToTop}  className="dropdown-item">Digital Innovation</NavLink>
                                <NavLink to="/intelligent-automation" onClick={scrollToTop}  className="dropdown-item">Intelligent Automation</NavLink>
                               
                            </NavDropdown>
                            
                            <NavDropdown 
                                className={`services mobile`}
                                title={<NavLink to="/what-we-do" onClick={handleClose} >What we do</NavLink>}
                                id={`offcanvasNavbarDropdown-whatwedo-expand-${expand}`}
                            >
                                <NavLink to="/cloud-and-application" onClick={handleClose}  className="dropdown-item">Cloud & Applications</NavLink>
                                <NavLink to="/data-and-ai" onClick={handleClose}  className="dropdown-item">Data & Artificial Intelligence</NavLink>
                                <NavLink to="/digital-innovation" onClick={handleClose}  className="dropdown-item">Digital Innovation</NavLink>
                                <NavLink to="/intelligent-automation" onClick={handleClose}  className="dropdown-item">Intelligent Automation</NavLink>
                               
                            </NavDropdown>

                            <NavLink className="nav-link" to="/what-we-think" onClick={handleClose} >What we think</NavLink>
                            {/* <NavLink className="nav-link" to="/ventures" onClick={scrollToTop} >Ventures</NavLink> */}
                           
                            <NavLink className="nav-link" to="/join-us" onClick={handleClose} >Join Us</NavLink>
                            <NavLink className="nav-link" to="/contact-us" onClick={handleClose} >Contact Us</NavLink>
                        </Nav>
                          
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
        </Container> 
    </div>
    <Outlet />
    </>
  )
}

export default Menu