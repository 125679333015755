import React from "react"
import "./clouddriven.css"

import { Row } from "react-bootstrap"
import { HashLink } from 'react-router-hash-link'

const CloudDriven = () => {
    
  return (
    <>
    <section className="clouddriven-section">
        <div className="container">
            <div className="main-box">
                    <h1>
                    Unleashing, <span>cloud-driven</span> <br /> 
                    brilliance or excellence
                    </h1>
            </div>
        </div>

        <div className="container-fluid px-0 mx-0 desktop">
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                        <div className="bg-1"></div>
                    {/* <img src={img1} alt="" /> */}
                </div>
                <div className="col-md-8 order-m-1">
                    <div className="text-side pe-5 ps--5">
                        <h3>Cloud Modernization</h3>
                        <h4>Thrive in the digital-fast lane</h4>
                        <p>We seamlessly integrate cutting-edge AI-driven solutions with user-friendly interfaces, simplifying the shift from manual to automated processes. With us on your side, you experience the future of infrastructure automation.</p>

                        <p>What sets us apart:</p>
                        <ul>
                            <li>Automation solutions tailored for specific business goals </li>
                            <li>Real-time monitoring and optimizations to maximize performance</li>
                            <li>Comprehensive solutions embedded with DevOps & DevSecOps best practices</li>
                            </ul> 
                            <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">AI-driven predictive maintenance  |  Edge computing  |  Dev-ops integration  |  IoT integration</p>
                  
                    </div>
                </div>
            </Row>
            <Row className="g-5">   
            <div className="col-md-8">
                <div className="text-side px-5 ms-5">
                        <h3>App Modernization</h3>
                        <h4>Stay agile, stay ahead</h4>
                        <p>From moving to a new runtime platform to improving the application's structure, from altering the app's architecture to completely rewriting the code from scratch, we help businesses leverage the benefits of advanced technologies while preserving the value of their existing investments in software.</p>

                        <p>What sets us apart:</p>
                        
                        <ul>
                            <li>Objective-led approach to drive maximum ROI </li>
                            <li>Agile and iterative development for continuous optimization</li>
                            <li>Integration of advanced technologies to build a robust, secure, and scalable foundation</li>
                            </ul> 
                            <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">API first design | Digital twins | Low-code No-code platforms | Green computing</p>
                  
                    </div>
                </div>
                <div className="col-md-4 text-center">
                    <div className="bg-2"></div>
                      {/* <img src={img2} alt="" /> */}
                </div>
            </Row>
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                      <div className="bg-3"></div>
                      {/* <img src={img3} alt="" /> */}
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5 ps--5">
                        <h3>Quality Engineering</h3>
                        <h4>Unlock next level excellence</h4>

                        <p>We blend state-of-the-art generative techniques with AI-driven analytics to deliver product and process excellence and spearhead innovation.</p>

                        <p>What sets us apart:</p>
                        <ul>
                          
                            <li>AI-led automated testing processes to generate test cases, reduce human error, and accelerate the testing process</li>
                            <li>Predictive quality analytics to proactively identify and resolve issues</li>
                            <li>Real-time feedback loop to enhance quality</li>
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">AI-driven testing | Machine learning-led predictive analytics | Automated compliance checks</p>
                    </div>
                </div>
            </Row>
            <Row className="g-5">   
            <div className="col-md-8">
                    <div className="text-side px-5 ms-5">
                        <h3>DevSecOps & SRE</h3>
                        <h4>Build system resilience</h4>

                        <p>We fuse DevSecOps and SRE principles into the software development lifecycle, to create resilient, secure, and efficient software systems.</p>
                        <p>What sets us apart:</p>

                        <ul>
                            <li>Embedded security checks all through the SDLC to identify and mitigate vulnerabilities early </li>
                            <li>Automated compliance monitoring</li>
                            <li>Proactive reliability engineering to minimize downtime</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                            <p className="inline-list">Shift-left security  |  End-to-End Automation  |  AI and ML led SRE  |  Chaos engineering</p>
                    </div>
                </div>
                <div className="col-md-4 text-center">
                      <div className="bg-4"></div>
                      {/* <img src={img4} alt="" /> */}
                </div>
            </Row>
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                      <div className="bg-5"></div>
                      {/* <img src={img5} alt="" /> */}
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5 ps--5">
                        <h3>Cybersecurity</h3>
                        <h4>Strengthen your business's safety net</h4>
                        <p>We leverage our enterprise technology and industry expertize to create a secure environment for business operations while minimizing risk from cyber threats.</p>

                        <p>What sets us apart:</p>
                        <ul>
                            <li>Customized solutions to build a robust cybersecurity posture that supports overall business strategy </li>
                            <li>Proactive threat intelligence to keep clients informed about emerging threats and vulnerabilities</li>
                            <li>AI ML-led solutions for more efficient and effective threat detection and response</li>
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">Endpoint Detection and Response (EDR) & Extended Detection and Response (XDR) | Zero Trust Security Models | Cloud security | IoT security | AI ML-led pattern recognition and response automation</p>                  
                    </div>
                </div>
            </Row>
            <Row>
                <div className="col-md-12 text-center py-5">
                    <HashLink to="#contact"  className="main-btn">Transform today</HashLink>
                </div>
            </Row>
        </div>

        <div className="container-fluid mt-5 px-0 mx-0 mobile tablet">
           <div className="bg-img bg-1">
                    <div className="text-side ">
                        <h3>Cloud Modernization</h3>
                        <h4>Thrive in the digital-fast lane</h4>
                        <p>We seamlessly integrate cutting-edge AI-driven solutions with user-friendly interfaces, simplifying the shift from manual to automated processes. With us on your side, you experience the future of infrastructure automation.</p>

                        <p>What sets us apart:</p>
                        <ul>
                            <li>Automation solutions tailored for specific business goals </li>
                            <li>Real-time monitoring and optimizations to maximize performance</li>
                            <li>Comprehensive solutions embedded with DevOps & DevSecOps best practices</li>
                            </ul> 
                            <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">AI-driven predictive maintenance  |  Edge computing  |  Dev-ops integration  |  IoT integration</p>
                  
                    </div>
            
           </div>

           <div className="bg-img bg-2">
                <div className="text-side">
                        <h3>App Modernization</h3>
                        <h4>Stay agile, stay ahead</h4>
                        <p>From moving to a new runtime platform to improving the application's structure, from altering the app's architecture to completely rewriting the code from scratch, we help businesses leverage the benefits of advanced technologies while preserving the value of their existing investments in software.</p>

                        <p>What sets us apart:</p>
                        
                        <ul>
                            <li>Objective-led approach to drive maximum ROI </li>
                            <li>Agile and iterative development for continuous optimization</li>
                            <li>Integration of advanced technologies to build a robust, secure, and scalable foundation</li>
                            </ul> 
                            <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">API first design | Digital twins | Low code no code platforms | Edge computing | Green computing</p>
                  
                    </div>
                </div>
            

                <div className="bg-img bg-3">
                    <div className="text-side">
                        <h3>Quality Engineering</h3>
                        <h4>Unlock next level excellence</h4>

                        <p>We blend state-of-the-art generative techniques with AI-driven analytics to deliver product and process excellence and spearhead innovation.</p>

                        <p>What sets us apart:</p>
                        <ul>
                          
                            <li>AI-led automated testing processes to generate test cases, reduce human error, and accelerate the testing process</li>
                            <li>Predictive quality analytics to proactively identify and resolve issues</li>
                            <li>Real-time feedback loop to enhance quality</li>
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">AI-driven testing | Machine learning-led predictive analytics | IoT integration | Automated compliance checks</p>
                    </div>
                </div>
           
                <div className="bg-img bg-4">
                    <div className="text-side">
                        <h3>DevSecOps & SRE</h3>
                        <h4>Build system resilience</h4>

                        <p>We fuse DevSecOps and SRE principles into the software development lifecycle, to create resilient, secure, and efficient software systems.</p>
                        <p>What sets us apart:</p>

                        <ul>
                            <li>Embedded security checks all through the SDLC to identify and mitigate vulnerabilities early </li>
                            <li>Automated compliance monitoring</li>
                            <li>Proactive reliability engineering to minimize downtime</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                            <p className="inline-list">Shift-left security  |  End-to-End Automation  |  AI and ML led SRE  |  Chaos engineering</p>
                    </div>
                </div>

                <div className="bg-img bg-5">
                    <div className="text-side">
                        <h3>Cybersecurity</h3>
                        <h4>Strengthen your business's safety net</h4>
                        <p>We leverage our enterprise technology and industry expertize to create a secure environment for business operations while minimizing risk from cyber threats.</p>

                        <p>What sets us apart:</p>
                        <ul>
                            <li>Customized solutions to build a robust cybersecurity posture that supports overall business strategy </li>
                            <li>Proactive threat intelligence to keep clients informed about emerging threats and vulnerabilities</li>
                            <li>AI ML-led solutions for more efficient and effective threat detection and response</li>
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">Endpoint Detection and Response (EDR) & Extended Detection and Response (XDR) | Zero Trust Security Models | Cloud security | IoT security | AI ML-led pattern recognition and response automation</p>                  
                    </div>
                </div>
            <Row>
                <div className="col-md-12 text-center">
                    <HashLink to="#contact" className="main-btn">Transform today</HashLink>
                </div>
            </Row>
        </div>
    </section>
    </>
  )
}

export default CloudDriven
