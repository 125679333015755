import React from 'react'
import { Row } from 'react-bootstrap'
import logo from '../images/footer-logo.webp';
import logomb from '../images/footer-logo-mb.webp';
import facebook from '../images/facebook.svg'
// import instagram from '../images/instagram.svg'
import twitter from '../images/twitter.svg'
import linkedin from '../images/linkedin.svg'

import './footer.css';
import { NavLink } from 'react-router-dom';
const Footer = () => {
        // const preventDefault = (e) => {
        //     e.preventDefault();
        //     e.stopPropagation(); // Prevent event propagation
        //     // Do whatever you need here
        //   };
    
  return (
    <footer>
      <div className='container'>
            <Row>
                <div className='col-md-3 cp'>
                    <img className='desktop' src={logo} alt=''/>
                    <img className='mobile' src={logomb} alt=''/>
                    <p>Empowering Visionaries,<br /> Engineering Success.  </p>
                    <div className='contactus'>
                        <a className='mobile' href="mailto:info@conceptvines.com">info@conceptvines.com</a><br />
                        <a className='mobile' href="tel:+12122544876">+1(212) 254-4876</a>  
                    </div>
             
                </div>
                <div className='col-md-2 offset-1 links'>
                    <h4>Links</h4>
                    <ul>
                        {/* <li><NavLink onClick={preventDefault}  to='/'>Sitemap</NavLink></li> */}
                        {/* <li><NavLink  to='/'>Social Icons</NavLink></li> */}
                        <li><NavLink to='/privacy-policy'>Privacy Policy</NavLink></li>
                        <li><NavLink to='/terms-and-condition'>Terms of Use</NavLink></li>
                        {/* <li><NavLink to='/contact-us'>Contact Us</NavLink></li> */}
                    </ul>
                </div>
                <div className='col-md-4 contactus desktop tablet'>
                    <h4>Contact Us</h4>  
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                    <a href="mailto:info@conceptvines.com">info@conceptvines.com</a><br />
                    <a href="tel:+12122544876">+1(212) 254-4876</a>
                </div>
            </Row>

            <Row>
                <div className='col-md-3 offset-9'>
                    <div className='d-flex'>
                        <NavLink target='_blank' to='https://linkedin.com/company/conceptvines'><img src={linkedin} alt='' /></NavLink>
                        <NavLink target='_blank' to='https://www.facebook.com/conceptvines'><img src={facebook} alt='' /></NavLink>
                        {/* <NavLink to='/'><img src={instagram} alt='' /></NavLink> */}
                        <NavLink target='_blank' to='https://twitter.com/conceptvines'><img src={twitter} alt='' /></NavLink>
                    </div>
                </div>
            </Row>

      </div>
       <div className='copyright'>
            © 2024 Copyright by ConceptVines. All rights reserved.
       </div> 
    </footer>
  )
}

export default Footer
